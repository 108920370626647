.dropdown-menu {
  top: 100%;
  left: 35px;
  right: .5rem;
}

.files-to-upload
{
  color: #6c757d;
  text-indent: 115px;
  margin-bottom: 22px;
  margin-top: 6px;
}

.upload-file-raw
{
  margin: 10px auto auto auto !important;
  width: 433px;
}

.document-name 
{
  color: #0070ad !important;
  font-size: 12px;
  font-weight: 500;
}

.document-title
{
  border-bottom: 1px solid #D3D2D1;
  color:grey;
  font-weight: 500;
}

.document-size
{
  color:grey;
  float: right;
}

.input-upload-file-hide
{
  visibility: hidden;
}

.icon-upload-document
{
  border-radius: 50% !important;
  border: 1px solid #0070ad !important;
  width: 50px;
  height: 50px;
  padding: 6px !important;
  background: none;
  color:#0070ad;
}

.btn-upload-document .icon-upload-document, .btn-upload-document .icon-upload-document:hover
{
  background: none;
}

.btn-upload
{
  margin-left: 197px !important;
}

div[role=document] .upload-area .dropdown-list-menu
{
  margin-top: 26px !important;
}

.dropdown-list-menu span
{
  color: #6c757d;
}

.dropdown-list-menu span, .dropdown-list-menu div, .dropdown-list-menu div:hover, .dropdown-list-menu div:focus
{
  text-indent: 8px !important;
  background: none;
  padding: 0px !important;
  width: 458px;
}

div[role=document] .upload-area .btn-upload-document {
  margin-top: 10px !important;
  margin-left: -55px !important;
}