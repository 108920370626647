.popup-upload
{
  margin-left: 17px !important;
  padding-top: 0px !important;
}

.popup-upload .app-container
{
  padding-right: 0px !important;
}

.popup-document div[role=document] div {
  max-width: 544px;
}

.popup-document div[role=document] div div
{
  margin: 0px !important;
  border:none;
}

.popup-document div[role=document] div .upload-area {
  border: 1px dashed;
  color: #D3D2D1;
  min-width: 478px;
  padding: 2px;
  height: 100%;
}

.uploadFiles
{
  color: #0070ad !important;
  margin-left: 17px;
}