.revision__pagination { 
  display: flex; 
  justify-content: space-between; 
  align-items: center; 
  margin: 0 0 20px; 
  padding: 0; 
  list-style-type: none; 
} 
 
.revision__pagination-previous, 
.revision__pagination-next { 
  display: inline-block; 
  text-decoration: none; 
} 
 
.revision__pagination-next { 
  transform: rotate(180deg); 
} 
 
.revision__pagination-previous, 
.revision__pagination-next, 
.revision__pagination-previous:hover, 
.revision__pagination-next:hover, 
.revision__pagination-previous:visited, 
.revision__pagination-next:visited { 
  color: #0c4178; 
} 
 
.revision__pagination-current { 
  margin: 0; 
} 
.card__revision-wrapper { 
  padding: 10px 40px 20px;
} 
 
.card__revision-wrapper nav { 
  border: none; 
} 
 
.visuallyhidden { 
  border: 0; 
  clip: rect(0 0 0 0); 
  height: auto; 
  margin: 0; 
  overflow: hidden; 
  padding: 0; 
  position: absolute; 
  width: 1px; 
  white-space: nowrap; 
} 

.rejected-revision-state 
{ 
  color:#808e9b; 
} 
 
.validated-revision-state 
{ 
  color: #95E616; 
} 