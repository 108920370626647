.adsk-viewing-viewer,
.canvas-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: auto;
}

/* BARRE D'OUTILS
   (écrase les styles par défaut du viewer)
------------------------------------------------------------------------------*/
.adsk-viewing-viewer .adsk-toolbar {
  display: flex !important;
  justify-content: center;
  align-items: center;
  transform: none;
}

.adsk-viewing-viewer .adsk-toolbar * {
  box-sizing: border-box;
}

/* Groupe de contrôles */
.adsk-control-group {
  margin: 0;
}

.adsk-viewing-viewer.light-theme .adsk-control-group {
  background: none;
  color: #4d9bc6;
  box-shadow: none;
  display: grid;
}

/* Bouton de la toolbar */
.adsk-viewing-viewer .adsk-button {
  position: relative;
  float: none;
  height: 56px;
  width: 56px;
  background: white;
  margin: 10px;
  transition: all ease-in 0.4s;
}

.adsk-viewing-viewer .adsk-button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Sous-barre de menu */
.adsk-button-arrow {
  position: absolute;
  top: -21px;
  right: calc(50% - 11px);
  color: #0c4178;
}

.adsk-viewing-viewer .adsk-button .toolbar-vertical-group {
  display: flex;
  flex-direction: column-reverse;
  left: -11px;
}

.toolbar-vertical-group .adsk-control {
  display: flex;
  align-items: center;
}

.markupLabel {
  position: absolute;
  white-space: nowrap;
  cursor: pointer;
}

/* .toolbar-vertical-group .adsk-control .adsk-control-tooltip {
  top: inherit;
  left: inherit;
  transform: none;
} */

.adsk-viewing-viewer .adsk-button,
.adsk-viewing-viewer.light-theme .adsk-button.active,
.adsk-viewing-viewer.light-theme .adsk-button:hover,
.adsk-viewing-viewer.light-theme .adsk-button:focus {
  border-radius: 28px;
  box-shadow: none;
  border: 1px solid #4d9bc6;
}

.adsk-viewing-viewer.light-theme .adsk-button.active,
.adsk-viewing-viewer.light-theme .adsk-button:hover,
.adsk-viewing-viewer.light-theme .adsk-button:focus {
  color: white;
  background: #4d9bc6;
}

.adsk-viewing-viewer .adsk-button label {
  display: none;
}

/* Tooltip des boutons de la toolbar */
.adsk-viewing-viewer.light-theme .adsk-control-tooltip,
.adsk-viewing-viewer
  .adsk-button
  > .toolbar-vertical-group
  ~ .adsk-control-tooltip,
.adsk-viewing-viewer
  .adsk-button
  > .toolbar-vertical-group
  > .adsk-button
  > .adsk-control-tooltip {
  top: calc(100% + 4px);
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0.7em;
  background: white;
  line-height: 1.4em;
  height: 1.4em;
  padding: 0 5px;
  color: #0070ad;
  visibility: visible;
}

/* style pour la popup Model du bouton breakdown du toolbar horizontal */
#ViewerModelStructurePanel {
  z-index: 300;
  max-height: calc(80%) !important;
}

.adsk-viewing-viewer.light-theme .adsk-button.active .adsk-control-tooltip {
  visibility: visible;
}

.adsk-viewing-viewer .adsk-control.adsk-hidden {
  display: none !important;
}

/* Right Toolbar verticale */

.right-vertical-toolbar {
  flex-direction: column;
  right: 10px;
  height: 100%;
  width: unset !important;
}

#toolbar-sectionToolSubMenu {
  left: -90px !important;
  bottom: -160px;
}

#toolbar-sectionToolarrow {
  transform: rotate(270deg);
  top: 16px;
  right: 60px;
}

.measure-settings-popup {
  height: 150px;
  width: 250px;
  top: 70%;
  right: 80px;
}

/* Toolbar verticale */

#toolbarId {
  position: absolute;
  top: 0;
  left: 30px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 7;
}

#toolbar-Vertical {
  position: static;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  width: 100%;
  transform: none;
}

#toolbar-Vertical .adsk-control {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 10px;
}

#toolbar-Vertical .adsk-button-arrow {
  right: -20px;
  top: calc(50% - 12px);
  transform: rotate(90deg);
  color: #0c4178 !important;
}

#toolbar-Vertical .adsk-button > .toolbar-vertical-group {
  left: 125%;
  bottom: -1px;
}

#toolbar-Vertical .adsk-button > .toolbar-vertical-group .adsk-control {
  margin: 0 10px;
}

/* Boutons personnalisés et refonte des anciennes icônes */

[class*=" adsk-icon-"]::before,
[class^="adsk-icon-"]::before,
.adsk-button-icon::before {
  font-family: dtp-iconfont, sans-serif !important;
}

/* Toolbar Autodesk */

.adsk-icon-first-person::before {
  font-family: adsk-viewing, sans-serif !important;
}

.adsk-icon-section-analysis::before {
  content: "\e929";
}

.adsk-icon-plane-x::before {
  content: "\e924";
}

.adsk-icon-plane-y::before {
  content: "\e925";
}

.adsk-icon-plane-z::before {
  content: "\e926";
}

.adsk-icon-box::before {
  content: "\e900";
}

.adsk-icon-explode::before {
  content: "\e91a";
}

.adsk-icon-structure::before {
  font-family: adsk-viewing, sans-serif !important;
}

.adsk-icon-measure::before {
  content: "\e91e";
}

.adsk-icon-measure-distance-new::before {
  content: "\e919";
}

.adsk-icon-measure-angle-new::before {
  content: "\e902";
}

.adsk-icon-measure-calibration::before {
  content: "\e90c";
}

.adsk-icon-measure-trash::before {
  content: "\e90f";
}

.adsk-icon-measure-settings::before {
  content: "\e91d";
}

.adsk-icon-settings::before {
  content: "\e92c";
}

.adsk-icon-share::before {
  content: "\e937";
}

/* Toolbar personnalisée */

/* Bouton de review dans la toolbar */
.button-combo-review > .adsk-button-icon::before {
  content: "\e904";
}

/* Démarrer une review */
.button-start-review > .adsk-button-icon::before {
  content: "\e92f";
}

/* Card des propriétés */
.button-content-card-property > .adsk-button-icon::before {
  content: "\e92d";
}

/* Card des rattachements */
.button-content-card-attach > .adsk-button-icon::before {
  content: "\e936";
}

/* Card des rattachements */
.button-content-card-share > .adsk-button-icon::before {
  content: "\e937";
}

/* Card du modèle */
.button-content-card-models-2d-3d > .adsk-button-icon::before {
  content: "\e92e";
}

/* Card de partage 
.button-content-card-share > .adsk-button-icon::before {
  content: "\e937";
}*/

.button-content-card-reporting > .adsk-button-icon::before {
  content: "\e935";
}
.button-breakdown > .adsk-button-icon::before {
  content: "\e934";
}

.button-view-markup > .adsk-button-icon::before {
  content: "\e905";
}

.button-create-markup > .adsk-button-icon::before {
  content: "\e903";
}

.button-arrow > .adsk-button-icon::before {
  content: "\e910";
}

.button-circle > .adsk-button-icon::before {
  content: "\e911";
}

.button-cloud > .adsk-button-icon::before {
  content: "\e912";
}

.button-freehand > .adsk-button-icon::before {
  content: "\e913";
}

.button-highlight > .adsk-button-icon::before {
  content: "\e914";
}

.button-pen > .adsk-button-icon::before {
  content: "\e915";
}

.button-polyline > .adsk-button-icon::before {
  content: "\e916";
}

.button-rectangle > .adsk-button-icon::before {
  content: "\e917";
}

.button-text > .adsk-button-icon::before {
  content: "\e918";
}

.button-explode > .adsk-button-icon::before {
  content: "\e91a";
}

#toolbar-measureTool-done > .adsk-button-icon::before {
  content: "\e90b";
}

#sliderHorizontal {
  writing-mode: bt-lr; /* IE */
  -webkit-appearance: slider-horizontal; /* WebKit */
  width: 75px;
  height: 75px;
  padding: 0 5px;
  position: absolute;
  background: white;
}
.adsk-viewing-viewer #toolbar-explodeTool {
  display: none !important;
}
