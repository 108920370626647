.modelDetailsAttribute {
  display: flex;
  text-indent: 10px;
}

.modelDetailsAttribute p {
  display: inline;
}

.expandedModel {
  margin-top: 10px !important;
}

.expansionTitle {
  margin: 0px;
  padding-top: 0px;
  min-height: 32px !important;
  text-indent: 10px;
}

.expansionDetailsModel {
  display: block !important;
  padding: 0px 24px 0px !important;
  margin-top: 4px;
}

.expansionTitleModel {
  min-height: 30px !important;
}

.expansionTitleModel div {
  color: #0c4178;
  margin: 0px !important;
  text-indent: 10px;
}

.expansionTitleModel div[role='button'] {
  float: left;
  right: 87% !important;
}

.expansionModel {
  box-shadow: none !important;
  /*position: inherit !important;*/
  margin: 0px !important;
  border: none !important;
}
