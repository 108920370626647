.noDataBorder {
  border-color: #FDD835;
  border-style: solid;
}

.cardMargin {
  margin: 12px 20px 12px 12px;
}

.menuItem:hover {
  color: #1CAEDC;
}

.copyDisplay{
  width: 100%;
}