.root {
  display: inline-flex;
  top: 0px;
  position: relative;
  width: 100%;
}

.drawer {
  display: inline-flex;
  vertical-align: top;
  width: 280px;
  flex-shrink: 0;
}
.toolbar {
  min-height: 56;
}

.content {
  flex-grow: 1;
  padding: 24px;
  position: relative;
}
