.card {
  display: flex;
  flex-direction: column;
  color: #4a4a4a;
}

.card--viewer {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(172, 172, 172, 0.5);
  padding-bottom: 10px;
  min-width: 200px;
  min-height: 300px;
}

.card__header {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 2.25rem;
  margin-bottom: 5px;
  padding: 10px 20px 5px;
  color: #0c4178;
  font-weight: 300;
  cursor: move;
}

.card__title-wrapper {
  display: flex;
  flex: 1 1 0;
  min-width: 0;
}

.card__icon {
  font-size: 1.5625rem;
  margin-right: 0.5rem;
  color: #4a4a4a;
}

.card__title {
  display: inline-block;
  flex: 1 1 0;
  min-width: 0;
  margin: 0;
  overflow: hidden;
  font-size: 0.875rem;
  font-weight: 300;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card__content {
  position: relative;
  /* padding: 0 15px; */
  font-size: 0.8125rem;
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.card-section__title {
  text-transform: uppercase;
  font-weight: normal;
  color: #0c4178;
  font-size: 0.875rem;
}

.card__actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 26px;
}

.card__actions .button {
  min-width: 10ch;
  margin-left: 1ch;
}

.card__footer {
  padding: 15px 20px 0;
}

.menuItem:hover {
  color: #1caedc;
}

/* Carte review - pagination
------------------------------------------------------------------------------*/
.card__revision-wrapper {
  /* padding: 10px 40px 20px; */
  padding: 0;
  margin-bottom: 20px;
  /* border-bottom: 1px solid #eaeaea; */
}

.card__revision-wrapper nav {
  border: none;
}

.revision__pagination {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0 20px;
  padding: 0;
  list-style-type: none;
}

.revision__pagination-previous,
.revision__pagination-next {
  display: inline-block;
  text-decoration: none;
}

.revision__pagination-next {
  transform: rotate(180deg);
}

.revision__pagination-previous,
.revision__pagination-next,
.revision__pagination-previous:hover,
.revision__pagination-next:hover,
.revision__pagination-previous:visited,
.revision__pagination-next:visited {
  color: #0c4178;
}

.revision__pagination-current {
  margin: 0;
}

/* FORMULAIRE DE CARTE GÉNÉRIQUE
------------------------------------------------------------------------------*/

.form * {
  box-sizing: border-box;
  font-weight: 300;
}

.form-section:not(:last-child) {
  margin-bottom: 16px;
}

.form-section--annotation-title,
.form-section--inline {
  display: flex;
  align-items: center;
}

.form-section--annotation-title {
  margin-bottom: 0;
}

.form-section--annotation-title .button {
  font-size: 0.875rem;
}

.form-section--actions {
  justify-content: flex-end;
}

.annotation-title {
  flex-grow: 1;
  margin-right: 1em;
}

.form-section__title {
  display: block;
  margin: 0;
  font-size: 0.8125rem;
}

.form-section--inline .form-section__title {
  flex-shrink: 0;
  min-width: 10ch;
}

.form-input {
  display: block;
  width: 100%;
  border: 1px solid #0c4178;
  border-radius: 11px;
  box-shadow: none;
  font-size: 0.6875rem;
  line-height: 1.125rem;
  background: transparent;
}

.form-input:hover,
.form-input:focus {
  border-color: #0070ad;
  outline: none;
}

.form-input--annotation-title {
  display: none;
}

.select {
  position: relative;
  display: flex;
  align-items: stretch;
  height: 1.375rem;
  margin: 8px 0 16px;
  overflow: hidden;
  border: 1px solid #0c4178;
  border-radius: 11px;
  cursor: pointer;
}

.select--full-width {
  width: calc(100% - 40px);
}

.select::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  width: 20px;
  /* background: no-repeat center url(images/icons/arrow-down.svg); TODO */
  background-color: white;
  background-size: 8px 8px;
  pointer-events: none;
}

.select select {
  -webkit-appearance: none;
  appearance: none;
  width: calc(100% + 30px);
  padding: 2px 58px 0 0;
  border: none;
  background: transparent;
  background-image: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.select option[data-default] {
  color: #9e9e9e;
}

input[type='text'],
input[type='email'] {
  height: 1.375rem;
  padding: 2px 10px;
}

textarea {
  padding: 4px;
}

.radio-group--inline {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
}

.radio-group--inline input {
  margin-right: 1ch;
}

label {
  margin: 0;
}

.form-section--actions .button {
  font-size: 0.8125rem;
  min-height: 22px;
  border-radius: 11px;
  text-transform: uppercase;
  padding: 6px 20px 4px;
}

/* Carte review - filtre
------------------------------------------------------------------------------*/
.card__filter-wrapper {
  padding: 20px 40px 10px;
  border-top: 1px solid #eaeaea;
}

.card__filter-toggle .icon {
  margin-left: 1ch;
  transform: rotate(-90deg);
}

.card__filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.card__filter-item {
  flex: 0 0 45%;
  margin: 10px 0 0;
}

.card__filter-label {
  margin-bottom: 0.5em;
  font-size: 0.625rem;
}

.card__filter .select {
  border-color: #4a4a4a;
  margin: 0;
}

.card__filter .card__select select {
  color: #4a4a4a;
}

.markup__priority.card-filter__radio {
  border: 1px solid #4a4a4a;
  border-radius: 11px;
  overflow: hidden;
}

.markup__priority.card-filter__radio .radio-button {
  flex: 1 0 0;
}

.markup__priority.card-filter__radio label {
  border: none;
}

.markup__priority.card-filter__radio .radio-button label {
  border-right: 1px solid #4a4a4a;
  border-radius: 0;
}

.markup__priority.card-filter__radio .radio-button:last-child label {
  border: none;
}

/* Carte review - annotations
------------------------------------------------------------------------------*/

.card__annotations-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.card__form .button {
  margin: 5px 0 10px;
}

.card__annotation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
}

.card__annotation-text {
  flex: 1 0 0;
  margin: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #0c4178;
}

.card__annotation.is-active {
  color: white;
  background: #0c4178;
}

.card__annotation--title .card__annotation-text {
  font-weight: bold;
  white-space: normal;
}

.card__annotation.is-active .card__annotation-text,
.card__annotation.is-active .button {
  color: white;
}

.card__annotation.is-inactive,
.card__annotation.is-inactive .card__annotation-text,
.card__annotation.is-inactive .button {
  color: #9b9b9b;
  background: #fff;
}

/* Carte review - Détail d'une annotation
------------------------------------------------------------------------------*/
.card__annotation-detail-wrapper {
  background: white;
  flex-grow: 1;
}

.card__annotation-return {
  padding: 15px 0 5px;
}

/* Carte review - sélecteur d'annotation
------------------------------------------------------------------------------*/
.card__annotation--title {
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1px solid #f2f2f2;
}

.card__annotation-previous,
.card__annotation-next {
  width: 17px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d8d8d8;
  font-size: 0.8em;
}

.card__annotation-previous {
  margin-right: 10px;
  border-radius: 0 34px 34px 0;
}

.card__annotation-previous .icon {
  display: inline-block;
  transform: rotate(180deg);
}

.card__annotation-next {
  margin-left: 10px;
  border-radius: 34px 0 0 34px;
}

/* Carte review - Informations sur une annotation
------------------------------------------------------------------------------*/
.card__annotation-info-wrapper {
  padding: 10px 40px;
  border-bottom: 1px solid #f2f2f2;
}

.card__annotation-info-title {
  font-size: 0.75rem;
}

.card__annotation-info-row {
  display: flex;
}

.card__annotation-info {
  flex: 1 1 0;
  min-width: 0;
  font-size: 0.6875rem;
  /* font-weight: 300; */
  margin-bottom: 0.5em;
}

.card__annotation-info-label,
.card__annotation-info-value {
  margin: 0;
}

.card__annotation-info-value {
  color: #9b9b9b;
}

/* Carte review - Commentaire
------------------------------------------------------------------------------*/
.card__comments-wrapper {
  font-size: 0.75rem;
  padding: 5px;
}

/* Commentaire gauche */
.card__comment-row {
  width: 85%;
  /* max-width: calc(100% - 3ch); */
  display: flex;
}

.card__comment-author {
  width: 20px;
  margin: 5px;
  line-height: 1;
  text-align: center;
  flex: 0 0 auto;
}

.card__comment {
  position: relative;
  margin: 5px 10px;
  padding: 6px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  flex-grow: 1;
}

.card__comment::before {
  position: absolute;
  top: -1px;
  left: -9px;
  content: '';
  /* background: #e5e5e5; */
  width: 10px;
  height: 0;
  display: block;
  border-top: 13px solid #e5e5e5;
  border-left: 8px solid transparent;
}

.card__comment::after {
  position: absolute;
  top: 0px;
  left: -7px;
  content: '';
  width: 10px;
  height: 0;
  display: block;
  border-top: 12px solid white;
  border-left: 7px solid transparent;
}
/* FIN de commentaire gauche */

/* Commentaire droite */
.card__comment-row--right {
  margin-left: auto;
  flex-direction: row-reverse;
}

.card__comment-row--right .card__comment::before {
  left: initial;
  right: -9px;
  border-left: none;
  border-right: 8px solid transparent;
}

.card__comment-row--right .card__comment::after {
  left: initial;
  right: -7px;
  border-left: none;
  border-right: 7px solid transparent;
}

/* Réponse au fil de commentaires */
.card__comment-answer {
  margin: 5px 0;
}

/* Carte review - Formulaire
------------------------------------------------------------------------------*/
.card__form {
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.card__form--comment {
  width: 85%;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 40px;
}

.card__form-row {
  width: 100%;
}

.card__form textarea {
  width: 100%;
  padding: 6px 10px;
  font-family: helvetica, arial, sans-serif;
  font-size: 0.75rem;
  resize: none;
  border: 1px solid #0c4178;
  border-radius: 10px;
  color: #4a4a4a;
  background: transparent;
}

/* ACCORDEON
------------------------------------------------------------------------------*/
.accordion__trigger {
  margin: 15px 0 5px;
}

.accordion__trigger-icon {
  position: relative;
  top: -1px;
  margin-right: 1ch;
  transition: transform ease-out 0.1s;
}

.accordion__trigger:not(.is-open) .accordion__trigger-icon {
  transform: rotate(-90deg);
}

.accordion__content {
  margin-left: calc(1em + 1ch);
}

.accordion__content:not(.is-open) {
  display: none;
}
/* FIN  - Accordeon */

/* LISTE D'ATTRIBUTS
------------------------------------------------------------------------------*/
.attributes-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.attribute {
  display: flex;
}

.attribute__name,
.attribute__value {
  margin: 0;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.attribute__name {
  flex: 1 0 calc(60% - 5px);
}

.attribute__name:not(:last-child) {
  max-width: 18ch;
}

.attribute__value {
  flex: 1 0 calc(40% - 5px);
}

/* FIN - Liste d'attributs */

/* ATTRIBUTS EN RELATION
------------------------------------------------------------------------------*/
.related {
  position: relative;
  margin-top: 20px;
  padding-top: 10px;
}

.related::before {
  content: '';
  position: absolute;
  top: 0;
  left: -15px;
  display: block;
  background: #0c4178;
  width: 100px;
  height: 1px;
}

.related .card-section__title {
  text-transform: none;
}
/* FIN  - Attributs en relation */

/* LISTE D'ANNOTATIONS
------------------------------------------------------------------------------*/
.annotation {
  margin-bottom: 15px;
  border-left: 1px solid #d0021b;
  padding-left: 5px;
}

.annotation__title {
  margin: 0;
  color: #0c4178;
  font-size: 0.8125rem;
  font-weight: normal;
}

.annotation__revision {
  margin-top: 5px;
}

.annotation__state {
  background: grey;
  text-align: center;
}

.annotation__from {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.6875rem;
}

.annotation__user {
  margin-right: 2ch;
  font-weight: bold;
}

.annotation__action {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.commentAnnotation {
  width: 100%;
  margin-bottom: 0.5em;
}

.annotation__action .button {
  background-color: #337cc8;
}

/* Formulaire de création d'annotation
------------------------------------------------------------------------------*/
.card__form-row {
  margin-bottom: 15px;
}
.markup__priority {
  display: flex;
  justify-content: space-between;
}
.markup__priority .radio-button {
  flex: 0 0 calc(25% - 10px);
}
.radio-button input {
  display: none;
}
.radio-button label {
  display: flex;
  justify-content: center;
  padding: 2px 6px;
  border: 1px solid #4a4a4a;
  border-radius: 11px;
  color: #4a4a4a;
  font-size: 0.6875rem;
  cursor: pointer;
}

.radio-button input:checked + label {
  background: #0c4178;
  color: white;
}

.card__select select {
  padding: 2px 24px 2px 6px;
  color: #0c4178;
  font-family: Helvetica, Arial sans-serif, sans-serif;
}

.card__select::after {
  background-color: transparent;
}
.card__select-larg {
  display: flex;
  justify-content: flex-start;
  padding: 13px 15px;
  align-items: center;
  width: 250px;
  font-size: 13px;
  font-weight: 300;
  border-radius: 14px;
}

.markup_disable {
  flex: 1 0 0;
  margin: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #808e9b !important;
}

/*     DP NOTIF   */
.dp-notif-right {
  padding: 5px;
}
.dp-notif-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 10px;
}
.dp-notif-right .dp-info-lil-title {
  font-size: 10px;
  font-weight: 300;
  color: #4a4a4a;
}
.dp-notif-right .dp-info-what {
  font-size: 12px;
  font-weight: 300;
  color: #9b9b9b;
}
.dp-notif-right .dp-info-what > b {
  font-weight: bold;
  color: #4a4a4a;
}
.dp-notif-right .dp-info-when {
  font-size: 9px;
  font-weight: 300;
  color: #0c4178;
}
/* END DP NOTIF   */
