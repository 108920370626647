/*
 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ██╗     
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗██║     
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║██║     
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║██║     
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║███████╗
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝
*/

*{
  outline-style:none;
  box-sizing: border-box;
}

html,
body {
  min-height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

body {
  font-size: 0.875rem;
  font-family: Helvetica, Arial sans-serif, sans-serif;
}

p {
  margin: 0 0 .5em;
}

dl,
dd,
dt {
  margin: 0;
}

iframe{
  border: 0px;
}

/* TODO */ 
nav{
  border-bottom: 1px solid #e0e0e0; 
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

option:not(:checked) {
  color: black; /* prevent <option>s from becoming transparent as well */
}

/*
██████╗  ██████╗ ███████╗██╗████████╗██╗ ██████╗ ███╗   ██╗███╗   ██╗██╗███╗   ██╗ ██████╗ 
██╔══██╗██╔═══██╗██╔════╝██║╚══██╔══╝██║██╔═══██╗████╗  ██║████╗  ██║██║████╗  ██║██╔════╝ 
██████╔╝██║   ██║███████╗██║   ██║   ██║██║   ██║██╔██╗ ██║██╔██╗ ██║██║██╔██╗ ██║██║  ███╗
██╔═══╝ ██║   ██║╚════██║██║   ██║   ██║██║   ██║██║╚██╗██║██║╚██╗██║██║██║╚██╗██║██║   ██║
██║     ╚██████╔╝███████║██║   ██║   ██║╚██████╔╝██║ ╚████║██║ ╚████║██║██║ ╚████║╚██████╔╝
╚═╝      ╚═════╝ ╚══════╝╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ 
                                                                                           
*/
.text-center{
  text-align: center;
}

.hide {
  display: none;
}

/*
 ██████╗ ██████╗ ██╗      ██████╗ ██████╗ 
██╔════╝██╔═══██╗██║     ██╔═══██╗██╔══██╗
██║     ██║   ██║██║     ██║   ██║██████╔╝
██║     ██║   ██║██║     ██║   ██║██╔══██╗
╚██████╗╚██████╔╝███████╗╚██████╔╝██║  ██║
 ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝  ╚═╝
*/

.primary{
  color: #0070ad !important;
}
.yellow{
  color: #ffc440 !important;
}
.green-light{
  color: #00dac5 !important;
}
.purple-light{
  color: #b0a7ff !important;
}

.secondary{
  color: #e0e0e0 !important;
}
.blue{
  color: #0c4178;
}

/*
██╗ ██████╗ ██████╗ ███╗   ██╗
██║██╔════╝██╔═══██╗████╗  ██║
██║██║     ██║   ██║██╔██╗ ██║
██║██║     ██║   ██║██║╚██╗██║
██║╚██████╗╚██████╔╝██║ ╚████║
╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝
*/

.icon {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

svg{ 
  fill: #757575;
}
svg:hover{ 
  fill: #0070AD;
}

/* Material design */
.material-icons.md-12 { font-size: 12px; }
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }


/*
██████╗ ██╗   ██╗████████╗████████╗ ██████╗ ███╗   ██╗
██╔══██╗██║   ██║╚══██╔══╝╚══██╔══╝██╔═══██╗████╗  ██║
██████╔╝██║   ██║   ██║      ██║   ██║   ██║██╔██╗ ██║
██╔══██╗██║   ██║   ██║      ██║   ██║   ██║██║╚██╗██║
██████╔╝╚██████╔╝   ██║      ██║   ╚██████╔╝██║ ╚████║
╚═════╝  ╚═════╝    ╚═╝      ╚═╝    ╚═════╝ ╚═╝  ╚═══╝
TODO A supprimer dès que tous les bouttons seront passé en material                                                      
*/
button,
.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border: none;
  background: none;
  cursor: pointer;
  color: #0c4178;
  line-height: 1;
  align-self: center;
  font-weight: 100;
}

.button-row .button {
  margin: 0 .5em;
}

.button:hover,
.button:focus {
  color: #0070ad;
}

.button:disabled,
.button:disabled:hover{
  background: #9e9e9e;
}


.button--primary {
  background: #0c4178;
  color: white;
}
.button--primary:hover,
.button--primary:focus {
  background: #0070ad;
  color: white;
}

.button--rounded {
  min-height: 22px;
  font-size: 0.8125rem;
  border-radius: 11px;
  text-transform: uppercase;
  padding: 5px 20px 4px;
}

.button--border {
  border: 1px solid currentColor;
}

.button--cancel {
  border-color: #d0021b;
}

.button--link {
  color: #0070ad;
  cursor: pointer;
  padding: 0;
  display: inline;
  line-height: inherit;
  text-align: left;
}

.button--link:hover span,
.button--link:focus span {
  text-decoration: underline;
}

.button--valid {
  background: #28965a;
  color: white;
  border: 1px solid #28965a;
  border-color: #7ed321;
}

.button--valid:hover,
.button--valid:focus {
  background: #28965a;
  color: white;
}

.button--base,
.button--base:hover,
.button--base:focus {
  color: #4a4a4a;
}

.button--icon {
  font-size: 1.2em;
}
.button-row {
  display: flex;
  align-items: center;
}

.button-row--center {
  justify-content: center;
}

.btn__ {
  margin-left: 5px;
  border-radius: 12px;
  text-align: center;
  margin-bottom: 5px;
}

button{
  outline: none !important;
}

/*
██████╗ ██████╗  ██████╗  ██████╗ ██████╗ ███████╗███████╗███████╗    ██████╗  █████╗ ██████╗ ███████╗
██╔══██╗██╔══██╗██╔═══██╗██╔════╝ ██╔══██╗██╔════╝██╔════╝██╔════╝    ██╔══██╗██╔══██╗██╔══██╗██╔════╝
██████╔╝██████╔╝██║   ██║██║  ███╗██████╔╝█████╗  ███████╗███████╗    ██████╔╝███████║██████╔╝███████╗
██╔═══╝ ██╔══██╗██║   ██║██║   ██║██╔══██╗██╔══╝  ╚════██║╚════██║    ██╔══██╗██╔══██║██╔══██╗╚════██║
██║     ██║  ██║╚██████╔╝╚██████╔╝██║  ██║███████╗███████║███████║    ██████╔╝██║  ██║██║  ██║███████║
╚═╝     ╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝╚══════╝    ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝
                                                                                                      
*/
/* All Progress Bars */
progress {
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 3px;

    /* Reset the default appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background: transparent;
}

progress::-webkit-progress-bar {
    background: transparent;
}

progress::-webkit-progress-value {
    background-color: #29d;
}

progress::-moz-progress-bar {
    background-color: #29d;
}

/* Determinate */
progress[value] {
    /* Reset the default appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

/* Indeterminate */
progress:not([value]) {
    /* Reset the default appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}


