.root {
    display: 'flex';
    flex-wrap: 'wrap';
}
  
.formControl {
    margin: 8px;
    display: 'flex';
    flex-wrap: nowrap;
}

.chips {
    display: 'flex';
    flex-wrap: 'wrap';
}

.chip {
    margin: 2;
}

.noLabel {
    margin-top: 24px;
}