.datasources_main.root {
  display: inline-flex;
  top: 0px;
  position: relative;
  width: 100%;
}

.datasources_main .drawer {
  display: inline-flex;
  vertical-align: top;
  width: 280px;
  flex-shrink: 0;
}

.datasources_main .toolbar {
  min-height: 56;
}

.datasources_main .content {
  flex-grow: 1;
  background-color: #fafafa;
  padding: 0 24px;
  position: relative;
  overflow: auto;
}
