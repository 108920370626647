#notifs_list {
  width: 400px;
  height: 300px;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

div#notifs_list::-webkit-scrollbar {
  width: 3px;
}
div#notifs_list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
div#notifs_list::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
div#notifs_list::-webkit-scrollbar-thumb:hover {
  background: #555;
}
