.navbar { 
  padding: .5em 1em 0; 
  background-color: white;
} 

.nav-logo 
{ 
  margin-top: 2px; 
}

.navbar-brand img {
  margin-right: 1ch;
}

.navbar-brand{
  padding-top: 0px;
  padding-bottom: 0px;
}

/* Items de navigation */
.nav-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  color: #9e9e9e;
  margin: 0 24px 0 0px;
}

.header-nav-infos button:hover ,
.nav-item:hover,
.nav-item:focus,
.nav-item:active {
  color: #0070ad;
  text-decoration: none;
}

.nav-item__icon {
  display: block;
  fill: currentColor;
  height: 25px;
  width: 25px;
  margin-left: -20%;
}

.nav-item__text {
  font-size: 0.6875rem;
  margin-top: 8px;
  color: #0070ad;
  font-family: Helvetica, Arial sans-serif, sans-serif !important;
  font-weight: normal;
}


.nav-item__badge {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1px;
  right: 17px;
  line-height: 13px;
  height: 13px;
  width: 13px;
  background: #ff8a8a;
  color: white;
  font-size: 0.625rem;
  border-radius: 50%;
}

.nav-item__project {
  position : relative;
  margin-left: 0px;
  margin-right: auto;
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  padding-top: 0px;
  transform: translateY(-50%);
  font-size: 13px;
}

.nav__logo__project{
  padding-bottom: 0px;
  margin-bottom: 0px;
}

/*    INFOS ACCOUNT   */
.header-nav-infos{
  position: relative;
  display: flex;
  align-items: center;
}
.header-nav-infos >.header-nav-notification.show-notif{
  position: relative;
}
.header-nav-infos .mL-30{
  padding: 0 17px;
}
.header-nav-infos .header-items-notifs{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.menuItem:hover {
  color: #1CAEDC;
}