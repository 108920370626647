.popin-overlay {
  position:fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popin {
  background: white;
  padding-bottom: 15px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.28);
  min-width: 350px;
}

.popin-small {
  min-width: 246px;
  text-indent: 8px;
  padding-bottom: 2px;

}

.popin__header {
  margin-bottom: 26px;
}

.popin-small .popin__header {
  margin-bottom: 0px; 
}

.popin-large {
  min-width: 1000px;
}

.popin .select {
  max-width: 35ch;
  margin: 8px auto 16px;
}

.popin__actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 26px;
}

.popin__actions .button {
  min-width: 10ch;
  margin-left: 1ch;
}


/* Radiobutton */
.radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
}
.radio input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radiomark {
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  display: block;
  border: 1px solid black;
  border-radius: 50%;
}
.radiomark:after {
  content: "";
  position: absolute;
  display: none;
}
.radio .radiomark:after {
  top: 2px;
  left: 2px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #0c4178;
}
.radio input:checked ~ .radiomark:after {
  display: block;
}
.radio input[type="radio"]:checked + span {
  background: #fff;
  width: 15px;
  height: 15px;
  display: block;
}
.radio span::after{
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgb(212, 39, 39);
}
.radio input[type="checkbox"]:focus + span{
  box-shadow: 0 0 5px lightblue;
}