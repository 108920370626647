.viewer-container {
  position: relative;
  grid-column: 1;
  grid-row: 2;
  align-self: stretch;
  justify-self: stretch;
  width: 100%;
  height: 100%;
}
.buttons {
  position: fixed;
  float: right;
  right: 4px;
  cursor: pointer;
}
.grid {
  border-style: solid;
  border-color: GhostWhite;
  border-width: 1;
}
